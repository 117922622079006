.sub-category-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.sub-category-title{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: white;
    height: 50px;
    padding: 10px;
    margin-bottom: 20px;
}

.sub-category-post-editor {
    width: 80%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 20px 0;
    padding: 10px 20px;
}

.ql-container{
    height: 250px;
}

.sub-category-navigation-container{
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.sub-category-page-nav-container{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.sub-category-breadcrumbs-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}