.post-page-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    box-shadow: 5px 5px 5px darkgray;
}

.post-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 30px;
}

.post-page-subcontainer{
    width: 100%;
    padding: 30px;
}

.post-title-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 20px;
    color: black;
}

.post-content-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 15px 30px;
}

.post-comment-editor{
    width: 95%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.post-title-info-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.post-title-info-text-container{
    display: flex;
    height: inherit;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: center;
    margin-right: 20px;
}

.post-bottom-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.post-icons-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.post-comment-count-container{
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.post-navigation-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.post-breadcrumbs-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.post-navigation-btns-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
}

.post-edit-btns-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
}