.footer-container{
    display: flex;
    flex-flow: row wrap;
    background-color: black;
    color: white;
    height: 100px;
    text-align: center;
    padding: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;
}

.footer-link{
    color: white !important;
    padding: 10px;
    margin: 5px;
}

.footer-link:hover{
    cursor: pointer;
}