.horse-card-container {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: 10px solid white;
    margin: 10px;
    min-width: 300px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    color: black;
    box-shadow: gray 2px 2px 3px;
}

.horse-card-name{
    font-family: "Roboto Slab ExtraBold", serif;
    font-size: 1.2em;
    text-align: center;
    margin: 4px;
}

.horse-card-breed{
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: 1em;
    text-align: center;
    margin: 2px;
}

.horse-card-parents{
    font-family: "Roboto Condensed Light", sans-serif;
    font-size: .8em;
    font-style: italic;
}

.horse-card-gender-img{
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
}

.horse-card-name-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    
}

.horse-card-photo-container{
    position: relative;
    width: 100%;
}

.horse-card-prefix{
    font-family: "Roboto Thin", sans-serif;
    font-weight: bold;
    font-size: .8em;
    vertical-align: top;
}