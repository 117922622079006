.exercise{
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    margin: 10px;
}

.exercise-title{
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    font-size: 1em;
    float: left;
    height: 20px;
    line-height: 20px;
    vertical-align: top;
    margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
    .exercise{
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        align-items: center;
        width: 45%;
        margin: 10px;
    }

    .exercise-title{
        font-family: "Roboto", sans-serif;
        font-weight: bolder;
        font-size: 1em;
        float: left;
        height: 20px;
        line-height: 20px;
        vertical-align: top;
        margin-bottom: 10px;
    }
}