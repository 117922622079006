.current-condition-bar{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    height: 20px;
    width: 95%;
    min-width: 300px;
    border-radius: 3px;
    margin: 20px 10px 10px 10px;
    border: 1px solid darkgray;
    background: rgba(256, 256, 256, .7); /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #c92a42 11%, #FF9797 11%, #FFDC5F 11%, #91F585 11%, #76cfc0 12%, #91F585 11%, #FFDC5F 11%, #FF9797 11%, #c92a42 11%); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #FF9797, #FFDC5F, #91F585, #76cfc0, #91F585, #FFDC5F, #FF9797); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #FF9797, #FFDC5F, #91F585, #76cfc0, #91F585, #FFDC5F, #FF9797); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #ff5972, #FF9797, #FFDC5F, #91F585, #76cfc0, #91F585, #FFDC5F, #FF9797, #ff5972); /* Standard syntax (must be last) */
    box-shadow: 2px 2px 6px darkgray;
}

.current-condition-bar-dark{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    height: 20px;
    width: 95%;
    min-width: 300px;
    border-radius: 3px;
    margin: 20px 10px 10px 10px;
    border: 1px solid #262626;
    background: rgba(256, 256, 256, .7); /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #FFDC5F, #FFDC5F, #91F585, #76cfc0, #91F585, #FFDC5F, #FFDC5F); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #FFDC5F, #FFDC5F, #91F585, #76cfc0, #91F585, #FFDC5F, #FFDC5F); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #FFDC5F, #FFDC5F, #91F585, #76cfc0, #91F585, #FFDC5F, #FFDC5F); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #FFDC5F, #FFDC5F, #91F585, #76cfc0, #91F585, #FFDC5F, #FFDC5F); /* Standard syntax (must be last) */
    box-shadow: 2px 2px 6px #262626;
}

.current-condition-indicator{
    height: 26px;
    width: 6px;
    border-radius: 1px;
    position: absolute;
    top: -4px;
    vertical-align: center;
    background-color: darkgray;
    box-shadow: inset 0 0 2px 0 rgba(0,0,0,.5);
    z-index: 3;
}

.step{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    height: 18px;
    width: 11%;
    position: absolute;
    vertical-align: center;
    z-index: 1;
    /*transform: translateX(-25%);*/
}

.step-indicator{
    width: 1px;
    height: 18px;
    background-color: rgba(256, 256, 256, .5);
}