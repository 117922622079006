.nav-bar-container {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
}

.navbar-upper-container {
    width: 100%;
    min-height: 100px;
    background-color: #022727;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.navbar-logo-img {
    width: 150px;
    margin: 0 20px;
}

.navbar-ranch-info-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 350px;
}

.navbar-info-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
}

.navbar-date-time-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}

.navbar-avatar-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin: 0 30px 0 20px;
}

.material-symbols-outlined {
    font-variation-settings: 'FILL' 1,
    'wght' 500,
    'GRAD' 0,
    'opsz' 48;
    color: white;
    position: absolute;
    right: 10px;
    top: 5px;
}

.user-menu {
    background-color: rgba(250, 250, 250, .9);
    min-width: 100px;
    border-radius: 0 0 10px 10px;
    z-index: 15;
}

.user-menu-item {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 12px;
    padding: 0;
}

.user-menu-item:hover {
    cursor: pointer;
}

.navbar-seasons-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
}

.seasons {
    height: 30px;
    width: auto;
    margin-right: 2px;
}

.email-notification-link {
    color: #e7e8e7 !important;
}

.login-register-links-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.login-register-link {
    margin-left: 10px;
}

.mobile-menu-container {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    padding: 10px;
    background-color: rgba(256, 256, 256, .8);
}

@media screen and (max-width: 875px) {
    .navbar-logo-img {
        display: none;
    }
    
    .navbar-ranch-info-container{
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
    }

    .navbar-date-time-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
    }

    .navbar-avatar-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 50px;
        margin: 0 10px 0 20px;
    }
}

@media screen and (max-width: 575px) {
    .navbar-logo-img {
        display: none;
    }

    .navbar-upper-container{
        width: 100%;
        min-height: 100px;
        background-color: #022727;
        display: flex;
        flex-flow: row wrap-reverse;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    .navbar-info-container {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 20px 0;
    }

    .navbar-ranch-info-container{
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }
}