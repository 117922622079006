.comment-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 20px 0;
    box-shadow: 0 0 5px darkgray;
}

.comment-title-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: black;
    padding: 10px 20px;
}

.comment-title-info-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

.comment-title-info-text-container{
    display: flex;
    height: inherit;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
}

.comment-title-btn-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
}

.comment-content-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 20px 15px;
}

.comment-editor{
    width: 95%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 0 20px 20px 20px;
}

.comment-replies-container{
    width: 95%;
    display: flex;
    flex-flow: column;
    margin-right: 20px;
    align-self: flex-end;
}