.announcements-container{
    display: flex;
    flex-flow: column nowrap;
}

.announcement-container{
    border-radius: 2px;
    margin: 20px 0;
    box-shadow: 3px 3px 3px darkgray;
}

.announcement-container-dark{
    box-shadow: 3px 3px 3px #262626;
}

.announcement-title-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: black;
    height: 50px;
    padding: 4px 15px 4px 20px;
    border-radius: 2px 2px 0 0;
}

.announcement-title{
    font-size: 28px;
    font-weight: bolder;
}

.announcement-content{
    padding: 10px 20px;
}