.horses-page{
    background-color: #f8f8f8;
    padding-bottom: 20px;
    min-height: 100vh;
    width: 100%;
}

.horses-banner{
    width: 100%;
}

.horses-section{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.horses-link{
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    min-width: 300px;
    margin: 10px;
}

.horses-link:hover {
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    margin: 10px;

}

.page-filters-pages-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
}

.horses-tabs{
    background-color: #044242;
    color: white;
    width: 100%;
}

.search-my-horses-filters-all-container{
    width: 100%;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    z-index: 3;
    padding: 20px;
    background-color: rgba(200, 200, 200, .95);
}

.search-my-horses-filters-container {
    flex-grow: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.search-my-horses-filters-container-dark {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(50, 50, 50, .95);
}

.search-my-horses-filters-sub-container{
    width: 85%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.search-my-horses-filter-sub-container{
    display: flex;
    flex-flow: column wrap;
    margin: 5px 15px 30px 15px;
}

.search-my-horses-filter-items-container{
    display: flex;
    flex-flow: column wrap;
    margin: 5px 20px 5px 0;
}

.search-my-horses-close-clear-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 450px) {
    .search-my-horses-filter-items-container{
        max-height: 680px;
    }
}