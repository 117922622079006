.carouselContainer{
    margin: 50px 0 50px 0;
    width:100%;
    display: flex;
    align-items: center;
}

.centerImg{
    width: 40%;
    height: 100%;

}

.leftImg{
    width: 30%;
    vertical-align: middle;
}

.rightImg{
    width: 30%;
    vertical-align: middle;
}