.read-notifications-container{
    width: 100%;
    margin: 30px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
    box-shadow: 2px 2px 4px darkgray;
}

.read-notifications-title-container{
    position: relative;;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.read-notifications-filters-container {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(256, 256, 256, .9);
}

.read-notifications-filters-sub-container{
    width: 85%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.read-notifications-filter-sub-container{
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 15px 30px 15px;
}

.read-notifications-filter-items-container{
    display: flex;
    height: 200px;
    flex-flow: column wrap;
    margin: 5px 20px 5px 0;
}
