.sales-horses-page{
    background-color: #f8f8f8;
    padding-bottom: 20px;
    min-height: 100vh;
    width: 100%;
}

.sales-horses-section{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.sales-horses-link{
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    min-width: 300px;
    margin: 10px;
}

.sales-horses-link:hover {
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    margin: 10px;

}