.existing-loan-container{
    width: 45%;
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
    box-shadow: 2px 2px 4px darkgray;
    padding-bottom: 10px;
    min-height: 200px;
}

.existing-loan-container-dark{
    box-shadow: 2px 2px 6px #262626;
}

.existing-loan-title-container{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.existing-loan-info-container{
    width: 95%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}