.divider{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: calc(100% + 48px);
    height: 48px;
    background-color: #044242;
    color: white;
    text-transform: uppercase;
    margin-left: -24px;
}