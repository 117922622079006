.inbox-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.inbox-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 25px;
}
