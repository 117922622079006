.player-info{
    margin: 15px 50px 15px 15px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.profile-image-cropper{
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    align-self: center;
    margin-bottom: 10px;
}

.profile-image{
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}

.kickstarter-image{
    width: 75px;
    height: 75px;
}