.welcome-container{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
}

.welcome{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.banner{
    width: 100%;
    margin-bottom: 5px;
    display: inline-block;
}

.welcome-img{
    position: relative;
    width: 45%;
    display: inline-block;
    margin-top: 50px;
    margin-right: 50px;
}

.horse-example{
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: auto;
    height: 500px;
}

.bodytype-slider-container{
    width: 80%;
}

.pattern-example{
    width:20%;
}

.click-me-img{
    position: absolute;
    top: -65px;
    right: -65px;
    padding: 35px;
    z-index: 2;
}

