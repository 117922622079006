.parent{
    background-color: #999999;
    font-family: Roboto, sans-serif;
    font-weight: lighter;
    border-radius: 5px;
    text-align: center;
    color: white;
    padding-right: 5px;
    padding-left: 5px;
}

.mare{
    background-color: lightpink;
    font-family: Roboto, sans-serif;
    font-weight: lighter;
    border-radius: 5px;
    text-align: center;
    color: white;
    padding-right: 5px;
    padding-left: 5px;
}

.stallion{
    background-color: lightskyblue;
    font-family: Roboto, sans-serif;
    font-weight: lighter;
    border-radius: 5px;
    text-align: center;
    color: white;
    padding-right: 5px;
    padding-left: 5px;
}