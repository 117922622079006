.horse-title{
    position: absolute;
    top: 70px;
    right: 90px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
}

.information{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.titles{
    width: 100%;
    flex-grow: 1;
    align-self: center;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 1em;
    margin-bottom: 5px;
}

.name{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-family: 'Roboto Black', sans-serif;
    font-size: 2.8vw;
    line-height: 1em;
    text-align: center;
    margin-bottom: 5px;
}

.name-text{
    padding: 0;
    margin: 0;
}

.breed{
    font-family: "Roboto Light", sans-serif;
    font-weight: bold;
    font-size: 2.1vw;
    text-align: center;
    margin-bottom: 2px;
}

.parents{
    font-family: Roboto, Thin, sans-serif;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
    font-size: 1.4vw !important;
    line-height: 1.2em;
}

.prefix{
    font-family: "Roboto Light", sans-serif;
    font-weight: bold;
    font-style: italic;
    margin-right: 5px;
}

.title-gender-img{
    position: relative !important;
    justify-self: flex-end;
    align-self: center;
}

@media screen and (max-width: 400px){
    .title{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        z-index: 2;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .information{
        /*width: 90% !important;
        padding: 0 !important;*/
    }

    .name{
        font-family: 'Roboto Black', sans-serif;
        font-size: 3.2vw;
        text-align: center;
        margin-bottom: 2px;
    }

    .breed{
        font-family: "Roboto Light", sans-serif;
        font-weight: bold;
        font-size: 2.4vw;
        text-align: center;
        margin-bottom: 1px;
    }

    .parents{
        font-family: Roboto, Thin, sans-serif;
        font-style: italic;
        font-weight: lighter;
        text-align: center;
        font-size: 1.8vw !important;
        line-height: 1.2em;
    }

    .title-gender-img{
        position: relative !important;
        max-width: 10px;
        justify-self: flex-end;
        align-self: center;
    }
}

@media screen and (max-width: 600px){
    .title{
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        z-index: 2;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .information{
        /*width: 90% !important;
        padding: 0 !important;*/
    }

    .name{
        font-family: 'Roboto Black', sans-serif;
        font-size: 3.2vw;
        text-align: center;
        margin-bottom: 4px;
    }

    .breed{
        font-family: "Roboto Light", sans-serif;
        font-weight: bold;
        font-size: 2.4vw;
        text-align: center;
        margin-bottom: 2px;
    }

    .parents{
        font-family: Roboto, Thin, sans-serif;
        font-style: italic;
        font-weight: lighter;
        text-align: center;
        font-size: 1.8vw !important;
        line-height: 1.2em;
    }
    
    .title-gender-img{
        position: relative !important;
        max-width: 20px;
        justify-self: flex-end;
        align-self: center;
    }
}

@media screen and (min-width: 1500px){
    .title{
        position: absolute;
        top: 70px;
        right: 90px;
        display: flex;
        align-items: center;
        z-index: 2;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .information{
        /*width: 90% !important;
        padding: 0 !important;*/
    }

    .name{
        font-family: 'Roboto Black', sans-serif;
        font-size: 3.2vw;
        text-align: center;
        margin-bottom: 45px;
    }

    .breed{
        font-family: "Roboto Light", sans-serif;
        font-weight: bold;
        font-size: 2.4vw;
        text-align: center;
        margin-bottom: 15px;
    }

    .parents{
        font-family: Roboto, Thin, sans-serif;
        font-style: italic;
        font-weight: lighter;
        text-align: center;
        font-size: 1.8vw !important;
        line-height: 1.2em;
    }

    .title-gender-img{
        position: relative !important;
        justify-self: flex-end;
        align-self: center;
    }
}