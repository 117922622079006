.mare-container{
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.mare-info-item{
    display: flex;
    flex-flow: row nowrap;
    width: 60%;
    align-items: center;
    justify-content: space-evenly;
    margin: 5px;
}