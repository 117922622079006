.task{
    padding: 2px;
    width: 100%;
    margin-bottom: 10px;
}

.task-title{
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    font-size: 1em;
    float: left;
    height: 20px;
    line-height: 20px;
    vertical-align: top;
    margin-bottom: 10px;
}

.task-bar{
    height: 10px;
    width: 100%;
    border-radius: 2px;
    display: inline-block;
    float: right;
    vertical-align: middle;
    right: 10px;
    position: relative;
    background-color: #044242;
    margin-bottom: 5px;
}

.task-indicator{
    height: 14px;
    width: 10px;
    border-radius: 2px;
    position: absolute;
    top: -2px;
    vertical-align: center;
    background-color: white;
    box-shadow: inset 0 0 10px 0 rgba(0,0,0,.3);
}

.task-labels{
    width: 100%;
    height: 20px;
    overflow: hidden;
    text-align: center;
}

.task-label-low{
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-weight: 100;
    font-size: .7em;
    float: left;
    line-height: 20px;
    vertical-align: middle;
}

.task-label-high{
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-weight: 100;
    font-size: .7em;
    margin-right: 15px;
    float: right;
    line-height: 20px;
    vertical-align: middle;
}