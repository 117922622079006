.club-chat-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 500px;
    min-width: 300px;
    width: 58%;
}

.club-chat-header-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
}

.club-chat-messages-container{
    overflow-y: auto;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
}

.club-chat-messages-subcontainer{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    padding: 15px;
}

.club-chat-new-message-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 10px;
    padding: 15px;
    align-self: center;
}