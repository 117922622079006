.user-search-controls{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 20px;
}

.users-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.users-container::after {
    content: "";
    flex: auto;
}

.link{
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-decoration: inherit;
    display: inline-block;
    width: fit-content;
    margin: 10px;
}

.link:hover {
    font-family: Roboto, sans-serif;
    font-weight: bolder;
    font-size: 20px;
    color: #044242;
    text-decoration: inherit;
    display: inline-block;
    width: fit-content;
    margin: 10px;
    cursor: pointer;
}