.training-section{
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: auto;
    padding: 30px;
}

.title-section{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
}

.training-task{
    width: 45%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.training-title{
    font-family: "Roboto Black", sans-serif;
    font-weight: bolder;
    font-size: 1.2em;
}

.rest-section{
    width: 50%;
}

@media screen and (max-width: 600px) {
    .training-task{
        width: 95%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .rest-section{
        width: 90%;
    }
}