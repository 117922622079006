.trash-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.trash-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 25px;
}

.trash-new-message-form-container{
    width: 95%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.trash-new-message-form-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}