.private-semen-card-container {
    width: 28%;
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    min-width: 330px;
    color: black;
    box-shadow: 3px 3px 3px darkgray;
}

.private-semen-card-container-dark {
    box-shadow: 2px 2px 6px #262626;
}

.private-semen-info-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.private-semen-horse-info {
    margin-top: 10px;
    padding: 0 10px;
    align-self: flex-start;
}

.private-semen-name {
    font-family: "Roboto Slab ExtraBold", serif;
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 4px;
}

.private-semen-breed {
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: .8em;
    text-align: left;
    margin-bottom: 2px;
}

.private-semen-purchase-info {
    margin-top: 15px;
    width: 30%;
    min-width: 100px;
}

.private-semen-price {
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    text-align: left;
}

.private-semen-prefix {
    font-family: "Roboto Thin", sans-serif;
    font-weight: bold;
    font-size: .8em;
    vertical-align: top;
}

.private-semen-name-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}
