.foundation-import-container{
    width: 30%;
    margin: 30px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
}

.foundation-import-title-container{
    position: relative;;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
    color: black;
}
