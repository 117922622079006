.landing-container{
    padding: 20px;
    display: flex;
    flex-flow: row wrap;
    min-height: 900px;
}

.landing-profile{
    max-width: 28%;
    flex-grow: 1;
}

.landing-img{
    width: 100%;
}

.landing-link{
    color: black;
    width: 22%;
    min-width: 250px;
    margin: 15px;
}

.landing-link:hover{
    text-decoration: none;
    color: black;
}

.landing-message{
    min-width: 200px;
    padding: 20px;
}

.landing-ranch-cards-container{
    display: flex;
    flex-flow: row wrap;
}

.landing-new-message-container{
    width: 100%;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
   .landing-container{
        padding: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        min-height: 900px;
   } 
    
    .landing-profile{
        max-width: 90%;
    }
}