.all-notifications-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
}

.notifications-box{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}

.notifications-btns-container{
    align-self: flex-end;
}

.no-new-notifications {
    align-self: center;
    margin-top: -10px;
}

.old-btn{
    margin: 5px;
    align-self: flex-end;
}