.info-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    min-width: 300px;
    height: fit-content;
    background-color: #f3f3f3;
    border-radius: 2px;
    box-shadow: 2px 2px 6px darkgray;
    margin-bottom: 20px;
}

.info-container-dark{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    min-width: 300px;
    height: fit-content;
    background-color: #3A3A3A;
    border-radius: 2px;
    box-shadow: 2px 2px 6px #262626;
    margin-bottom: 20px;
}

.info-header-container{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #DB9200;
    min-height: 50px;
    height: fit-content;
    padding: 5px;
    border-radius: 2px 2px 0 0;
}

.info-items-container{
    width: 100%;
    padding: 20px;
}

.info-link{
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 100%;
}

.info-link:hover {
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
}



