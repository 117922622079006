.horsesBanner{
    width: 100%;
}

.studs-link{
    color: black;
    text-decoration: none;
    display: inline-block;
    width: 28%;
    min-width: 300px;
    margin: 10px;
}

.studs-link:hover {
    color: black;
    text-decoration: none;
    display: inline-block;
    width: 28%;
    margin: 10px;

}