.register{
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    width: 100%;
    padding-top: 50px;
}

.validation-container{
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    width: 100%;
    padding-top: 50px;
}