.stat{
    padding: 2px;
    width: 45%;
    margin-bottom: 20px;
}

.stat-title{
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    font-size: 1.2em;
    float: left;
    height: 20px;
    line-height: 20px;
    vertical-align: top;
    margin-bottom: 10px;
}

.stat-bar{
    height: 12px;
    width: 100%;
    border-radius: 2px;
    display: inline-block;
    float: right;
    vertical-align: middle;
    right: 10px;
    position: relative;
    margin-bottom: 5px;
}

.stat-indicator{
    height: 16px;
    width: 10px;
    border-radius: 2px;
    position: absolute;
    top: -2px;
    /*left: 10%;*/
    vertical-align: center;
    background-color: white;
    box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,.3);
}

.stat-labels{
    width: 100%;
    height: 20px;
    overflow: hidden;
    text-align: center;
}

.stat-label-low{
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 1em;
    float: left;
    line-height: 20px;
    vertical-align: middle;
}

.stat-label-mid{
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 1em;
    line-height: 20px;
    vertical-align: middle;
    padding-top: -20px;
    margin-top: -10px;
}

.stat-label-mid-left{
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 1em;
    line-height: 20px;
    vertical-align: middle;
    padding-top: -20px;
    margin-top: -10px;
    padding-right: 40px;
}

.stat-label-mid-right{
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 1em;
    line-height: 20px;
    vertical-align: middle;
    padding-top: -20px;
    margin-top: -10px;
    padding-left: 40px;
}

.stat-label-high{
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-size: 1em;
    margin-right: 15px;
    float: right;
    line-height: 20px;
    vertical-align: middle;
}

.stat-number-display{
    height: 12px;
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    padding: 0 10px 0 10px;
    margin-top: -2px;
    float: right;
}


@media screen and (max-width: 600px) {
    .stat{
        padding: 2px;
        width: 95%;
        margin-bottom: 20px;
    }
}

