.genetic-tests-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 30px;
}

.genetic-test-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 30%;
    min-width: 300px;
    background-color: #f3f3f3;
    border-radius: 2px;
    box-shadow: 2px 2px 6px darkgray;
}

.genetic-test-container-dark{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 30%;
    min-width: 300px;
    background-color: #3A3A3A;
    border-radius: 2px;
    box-shadow: 2px 2px 6px #262626;
}

.genetic-test-title{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #DB9200;
    height: 50px;
    padding: 5px;
    border-radius: 2px 2px 0 0;
}

.genetic-test-interface{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.genetic-test-data{
    width: 100%;
    padding: 20px;
}