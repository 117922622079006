.trophy-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.trophy-img{
    width: 180px
}

.trophy-discipline{
    font-family: Roboto, sans-serif;
    font-weight: bold;
    margin-bottom: -5px;
}