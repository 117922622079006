.shows-banner{
    width: 100%;
}

.shows-container{
    width: 95%;
    margin: 30px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
    box-shadow: 2px 2px 4px darkgray;
}

.shows-title-container{
    position: relative;;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.shows-gender-icon{
    height: 25px;
}

.shows-filters-container {
    width: 95%;
    max-height: 1200px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(200, 200, 200, .95);
}

.shows-filters-container-dark {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(50, 50, 50, .95);
}

.shows-filters-sub-container{
    width: 85%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.shows-filter-sub-container{
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 15px 30px 15px;
}

.shows-filter-items-container{
    display: flex;
    max-height: 380px;
    flex-flow: column wrap;
    margin: 5px 20px 5px 0;
}

.shows-close-clear-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}