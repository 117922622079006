.custom-import-container{
    width: 60%;
    margin: 30px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
}

.custom-import-title-container{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
    color: black;
}

.custom-import-sub-container{
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}