.new-message-form-container{
    width: 90%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.new-message-form-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
}