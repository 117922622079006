.photo-container{
    position: relative;
    height: fit-content;
    text-align: center;
    z-index: 0;
    width: 100%;
}

.background{
    max-width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;
}

.base{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.pangare{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.dapples{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    mix-blend-mode: multiply;
}

.sooty{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    mix-blend-mode: multiply;
}

.left-fore{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
}

.right-fore{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 6;
}

.left-hind{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 7;
}

.right-hind{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8;
}

.forehead{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
}

.nose{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}

.pinto{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
}

.leopard{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 12;
 }

.roan{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 13;
}

.mane{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 14;
}

.mane-white{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 15;
}

.appy-mane{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 16;
}

.appy-tail{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 17;
}

.back-bottom{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 18;
}

.back-top{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 19;
}

.head{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
}

.pregnant{
    max-width: 10%;
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 21;
}

.nursing{
    max-width: 20%;
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 21;
}

.for-sale-img{
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 22;
}

.photo-titles-container{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 23;
}