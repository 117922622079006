.ranch-search-container{
    width: 30%;
    min-width: 300px;
    background-color: #efefef;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 15px 30px 15px;
    box-shadow: 2px 2px 6px darkgray;
}

.ranch-search-container-dark{
    width: 30%;
    min-width: 300px;
    background-color: #3A3A3A;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 15px 30px 15px;
    box-shadow: 2px 2px 6px #262626;
}

.ranch-search-title-container{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #DB9200;
    height: 50px;
    padding: 5px;
    border-radius: 2px 2px 0 0;
}

.ranch-search-image-cropper{
    width: 180px;
    height: 180px ;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    align-self: center;
    margin: 10px;
    background-color: #999999;
    box-shadow: gray 2px 2px 3px;
}

.ranch-search-background{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background-image: url("https://media.legacyhorsegame.com/misc/ranch-search-background.jpg");
    background-size: 100%;
}

.ranch-search-image{
    display: inline;
    margin: 0 auto;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.ranch-search-info-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

