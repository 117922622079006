.semen-card-container {
    width: 28%;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    min-width: 330px;
    color: black;
    
}

.semen-info-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.semen-horse-info {
    margin-top: 10px;
    padding: 0 10px;
    align-self: flex-start;
}

.semen-name {
    font-family: "Roboto Slab ExtraBold", serif;
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 4px;
}

.semen-breed {
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: .8em;
    text-align: left;
    margin-bottom: 2px;
}

.semen-parents {
    font-family: "Roboto Condensed Light", sans-serif;
    font-size: .6em;
}

.semen-purchase-info {
    margin-top: 15px;
    width: 30%;
    min-width: 100px;
}

.semen-price {
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    text-align: right;
}

.semen-prefix {
    font-family: "Roboto Thin", sans-serif;
    font-weight: bold;
    font-size: .8em;
    vertical-align: top;
}

.semen-name-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}
