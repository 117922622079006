.club-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.club-banner-img{
    width: 100%;
}

.club-info-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 30px;
}

.club-info-subcontainer{
    width: 40%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.club-info-item-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.club-members-list{
    width: 100%;
    margin: 20px;
}

.club-members-list-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.club-ranches-list{
    width: 100%;
    margin: 20px;
}

.club-ranches-list-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.club-list-container{
    width: 100%;
    height: 500px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    overflow-y: auto;
    padding: 20px;
}

.club-recent-titles-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 20px;
    min-height: 200px;
}

.club-recent-titles-subcontainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.club-recent-foals-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 20px;
    min-height: 200px;
}

.club-recent-foals-subcontainer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.club-rankings-container{
    width: 100%;
    padding: 30px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.club-rankings-table-container{
    position: relative;
    width: 95%;
}

.club-rankings-filters-container{
    width: 100%;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    z-index: 3;
    padding: 20px;
    background-color: rgba(200, 200, 200, .95);
}

