.sub-category-card-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 10px;
}

.sub-category-card-info-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    flex-grow: 1;
    margin-right: 10px;
}

.sub-category-card-counts-container{
    width: 10%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 5px;
}