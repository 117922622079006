.contact-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.contact-buttons{
    align-self: flex-end;
    justify-self: flex-end;
}