.ribbon-container{
    position: relative;
    height: 180px;
}

.total-wins{
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 20px;
    font-family: Roboto, sans-serif;
    font-weight: bolder;
}

.ribbon{
    position: relative;
    width: 100px;
}