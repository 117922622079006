.stud-card-container{
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    padding: 10px 10px 5px 10px;
    margin: 10px;
    min-width: 300px;
}

.stud-info-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 10px;
}

.stud-name{
    font-family: "Roboto Slab ExtraBold", serif;
    font-size: 1.2em;
    text-align: left;
    margin: 10px 4px 4px 4px;
}

.stud-breed{
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: .8em;
    text-align: left;
    margin: 2px;
}

.stud-parents{
    font-family: "Roboto Condensed Light", sans-serif;
    font-size: .6em;
}

.stud-price{
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    font-size: 1.4em;
    padding-top: 10px;
    align-self: flex-end;
}

.gender-img{
    position: absolute;
    z-index: 1;
}

.stud-prefix{
    font-family: "Roboto Thin", sans-serif;
    font-weight: bold;
    font-size: .8em;
    vertical-align: top;
    padding-top: 10px;
}

.stud-name-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}