.update-location-container{
    width: 30%;
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
    padding-bottom: 10px;
}

.update-location-title-container{
    position: relative;;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: black;
}