.album-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.photos-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.photo-card-container{
    position: relative;
    width: fit-content;
    margin: 10px;
}

.photo-card{
    width: 300px;
    background-color: white;
    border-radius: 5px;
    border: 10px solid white;
    min-width: 300px;
}

.enlarged-photo-container{
    margin: 10px;
    position: relative;
    align-self: center;
    width: 85%;
}

.enlarged-photo{
    width: 100%;
    margin: 10px;
}

.close-button{
    color: white;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 2;
}

.photo-thumbnail{
    width: 150px;
    margin: 5px;
}

.selected-photo-thumbnail{
    width: 150px;
    margin: 5px;
    border: 5px solid white;
}

.save-photo-container{
    display: flex;
    flex-flow: row wrap;
    width: 95%;
    min-width: 300px;
    justify-content: space-evenly;
    margin-top: 50px;
}

.save-photo-form-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    align-self: flex-start;
    padding-right: 20px;
    width: 30%;
}