.ranch-card{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
}

.ranch-card-image-cropper{
    width: 180px;
    height: 180px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 10px;
    box-shadow: 3px 3px 3px darkgray;
}

.ranch-card-image-cropper-dark{
    box-shadow: 3px 3px 3px #262626;
}

.ranch-card-image{
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}

.ranch-card-name-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 10px;
    text-align: center;
    transform: translateY(-40px);
    box-shadow: 3px 3px 3px darkgray;
}

.ranch-card-name-container-dark{
    box-shadow: 3px 3px 3px #262626;
}