.horse{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
}

.horse-photo-container{
    position: relative;
    width: 100%;
}

.nav-horse{
    position: absolute;
    left: 20px;
    top: 50px;
    z-index: 1;
}

.link-horse{
    color: black;
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    text-decoration: inherit;
    display: inline-block;
    margin: 10px;
}

.link-horse:hover {
    color: black;
    text-decoration: inherit;
    display: inline-block;
    margin: 10px;

}

.horse-tabs{
    background-color: #044242;
    color: white;
    width: 100%;
    display: flex;
    justify-items: center;
}

.horse-tab{
    width: 100%;
}

.horse-album-tab{
    width: 100%;
    background-color: lightgray;
}

@media only screen and (max-width: 600px) {
    .nav-horse{
        position: absolute;
        left: 20px;
        top: 10px;
    }

    .horse-tab{
        width: 100%;
        padding: 5px !important;
    }

    .horse-album-tab{
        width: 100%;
        background-color: lightgray;
    }
}
