.general-imports-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    justify-items: flex-start;
}

.general-locations-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    justify-items: flex-start;
}