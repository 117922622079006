.message-thread-container{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.message-thread-subcontainer{
    width: 98%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.message-thread-header-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    min-height: 50px;
}

.message-thread-tools-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
}

.message-thread-link-btn{
    color: white !important;
}

.message-thread-new-message-form-container{
    width: 95%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 50px;
}

.message-thread-new-message-form-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
}