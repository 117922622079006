.lease-card-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 28%;
    background-color: white;
    border-radius: 5px;
    border: 15px solid white;
    margin: 10px;
    min-width: 330px;
    position: relative;
    color: black;
    box-shadow: 3px 3px 3px darkgray;
}

.lease-card-gender-img{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    height: 30px;
}

.lease-card-info-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    margin: 10px;
}

.lease-card-name-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.lease-card-btns-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
}