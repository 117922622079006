.pedigree-container{
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    width: 100%;
    min-height: 700px;
}

.pedigree-column{
    width: 15%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    min-height: inherit;
    padding: 10px;
}

@media screen and (max-width: 600px) {
    .pedigree-container{
        display: flex;
        flex-flow: row nowrap;
        align-content: center;
        justify-content: center;
        width: 100%;
        min-height: 700px;
    }

    .pedigree-column{
        width: 30%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        min-height: inherit;
        padding: 10px;
        margin: 10px;
    }
}