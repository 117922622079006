.latest-container{
    width: 30%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 30px;
    box-shadow: 5px 5px 5px darkgray;
}

.latest-title-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 5px;
    color: black;
}