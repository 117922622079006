.stallion-container{
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.stallion-breedings-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 45%;
    margin-bottom: 20px;
}