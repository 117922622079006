.chat-message-container{
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    width: 90%;
}

.chat-message-info-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 5px;
}