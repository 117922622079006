.notification-container{
    background: #ebebeb;
    padding: 2px 10px 2px 10px;
    border-radius: 3px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    box-shadow: 2px 2px 3px darkgray;
    margin: 1px;
}

.notification-content-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}