.show{
    width: 100%;
    text-align: center;
    padding: 20px;
    background-color: lightgray;
    min-height: 100vh;
}

.showTitle{
    font-family: "Roboto Slab", serif;
    font-weight: bold;
    font-size: 1.4em;
}

.showEventDate{
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1.2em;
}

.showEntryFee{
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    font-size: 1em;  
}

.showPurse{
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    font-style: italic;
    font-size: 1em;
}

.showEntrantsTitle{
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1.4em;
    margin-top: 20px;
}

.showEntrants{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.show-link{
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    min-width: 300px;
    margin: 10px;
}

.show-link:hover {
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    margin: 10px;

}