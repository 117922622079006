.private-sales-container{
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
    box-shadow: 2px 2px 4px darkgray;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.private-sales-title-container{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 20px;
}

.private-sales-sub-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    justify-items: flex-end;
}