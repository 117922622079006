.auction-card-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 28%;
    background-color: white;
    border-radius: 5px;
    border: 15px solid white;
    margin: 10px;
    min-width: 330px;
    position: relative;
    color: black;
}

.auction-info-container{
    margin-top: 15px;
}

.auction-name{
    font-family: "Roboto Slab ExtraBold", serif;
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 4px;
}

.auction-breed{
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: .8em;
    text-align: left;
    margin-bottom: 2px;
}

.auction-parents{
    font-family: "Roboto Condensed Light", sans-serif;
    font-size: .6em;
    margin-bottom: -3px;
}

.auction-purchase-info{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: 15px;
}

.auction-price{
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    text-align: right;
    padding: 5px 15px 0 0;
}

.auction-prefix{
    font-family: "Roboto Thin", sans-serif;
    font-weight: bold;
    font-size: .8em;
    vertical-align: top;
}

.auction-name-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}


.auction-end-date{
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: .7em;
    text-align: left;
    margin-bottom: 2px;
}

.current-bidder{
    font-family: "Roboto", sans-serif;
    font-size: .8em;
    text-align: right;
    margin-bottom: 0;
}

.auction-gender-img{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    height: 30px;
}