.active-club-card-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 25%;
    min-width: 300px;
    border-radius: 2px;
    box-shadow: 2px 2px 6px darkgray;
    margin: 20px;
}

.active-club-card-title-container{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #DB9200;
    height: 50px;
    padding: 5px;
    border-radius: 2px 2px 0 0;
    margin-bottom: 5px;
}

.active-club-info-container{
    width: 98%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 15px;
}

.active-club-info-item-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.active-club-logo-img{
    width: 50%;
    padding-bottom: 15px;
}

.active-club-link{
    color: black;
    text-decoration: none !important;
}

.active-club-link:hover{
    color: black;
}