.discipline-ribbons-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 30%;
    min-width: 300px;
    background-color: #f3f3f3;
    border-radius: 2px;
    box-shadow: 2px 2px 6px darkgray;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.discipline-ribbons-container-dark{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 30%;
    min-width: 300px;
    background-color: #3A3A3A;
    border-radius: 2px;
    box-shadow: 2px 2px 6px #262626;
    margin-bottom: 30px;
    padding-bottom: 20px;
}


.discipline-ribbons-title{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #DB9200;
    height: 50px;
    padding: 5px;
    border-radius: 2px 2px 0 0;
}

.ribbons-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 10px;
}

.discipline-ribbons-info-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    padding: 5px 15px;
}