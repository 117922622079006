.public-sales-link{
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    min-width: 300px;
    margin: 10px;
}

.public-sales-link:hover {
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    margin: 10px;

}