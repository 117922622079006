.condition-change-bar{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    height: 20px;
    width: 95%;
    min-width: 300px;
    border-radius: 3px;
    margin: 10px;
    border: 1px solid darkgray;
    background: rgba(256, 256, 256, .7); /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #ffc252, #91F585, #ffc252); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #ffc252, #91F585, #ffc252); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #ffc252, #91F585, #ffc252); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #ffc252, #91F585, #ffc252); /* Standard syntax (must be last) */
    box-shadow: 2px 2px 6px darkgray;
}

.condition-change-bar-dark{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    height: 20px;
    width: 95%;
    min-width: 300px;
    border-radius: 3px;
    margin: 10px;
    border: 1px solid #262626;
    background: rgba(256, 256, 256, .7); /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #ffc252, #91F585, #ffc252); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #ffc252, #91F585, #ffc252); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #ffc252, #91F585, #ffc252); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #ffc252, #91F585, #ffc252); /* Standard syntax (must be last) */
    box-shadow: 2px 2px 6px #262626;
}

.condition-change-indicator{
    height: 26px;
    width: 6px;
    border-radius: 1px;
    position: absolute;
    top: -4px;
    vertical-align: center;
    background-color: darkgray;
    box-shadow: inset 0 0 2px 0 rgba(0,0,0,.5);
    z-index: 2;
}

.middle-marker{
    height: 18px;
    width: 3px;
    left: 50%;
    transform: translateX(50%); 
    position: absolute;
    background-color: rgba(256, 256, 256, .6);
    z-index: 1;
}

.middle-marker-dark{
    height: 18px;
    width: 3px;
    left: 50%;
    transform: translateX(50%);
    position: absolute;
    background-color: #232323;
    z-index: 1;
}

.step-indicator{
    position: absolute;
    width: 1px;
    height: 18px;
    background-color: rgba(256, 256, 256, .4);
    z-index: 1;
}