.comparisons-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 20px;
}

.comparisons-sub-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}