.canister-container{
    width: 95%;
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
    box-shadow: 2px 2px 4px darkgray;
    padding-bottom: 10px;
}

.canister-title-container{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.canister-photo-container{
    width: 45%;
}

.canister-sub-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
}

.canister-info-container{
    width: 50%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
}

.canister-straws-info-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.canister-form-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}