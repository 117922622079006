.current-level-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 6px;
    background-color: whitesmoke;
    border-radius: 3px;
    padding: 5px 10px;
}

.current-level-discipline{
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bolder;
    margin: 5px 0 0 0;
}

.current-level-level{
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    margin: 0;
}