.comparison-trait-bar {
    color: black;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    width: 100%;
    margin: 5px;
    min-width: 280px;
    border-radius: 3px;
    border: 1px solid darkgrey;
    vertical-align: middle;
    right: 10px;
    position: relative;
    background: rgba(256, 256, 256, .7); /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, #FF9797, #FFDC5F, #62FF94, #00C1CD); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #FF9797, #FFDC5F, #62FF94, #00C1CD); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #FF9797, #FFDC5F, #62FF94, #00C1CD); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, #FF9797, #FFDC5F, #62FF94, #00C1CD); /* Standard syntax (must be last) */
    box-shadow: 2px 2px 6px darkgray;
}

.comparison-trait-bar-dark {
    box-shadow: 2px 2px 6px #262626;
}

.comparison-trait-indicator-current{
    height: 22px;
    width: 8px;
    border-radius: 3px;
    position: absolute;
    top: -2px;
    vertical-align: center;
    background-color: lightgray;
    box-shadow: inset 0 0 2px 0 rgba(0,0,0,.5);
    z-index: 2;
}

.comparison-trait-indicator-potential {
    /*width: 40px;*/
    padding-bottom: 3px;
    transform: rotate(-90deg)  translateY(-6px);
    position: absolute;
    font-family: "Roboto Thin", sans-serif;
    font-weight: bold;
    font-size: .5em;
    color: white;
    z-index: 1;
}





