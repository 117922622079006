.profile-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.profile-sub-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}

.information-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 10px;
    width: 25%;
    min-width: 300px;
}

.charts{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    min-width: 300px;
    width: 30%;
    max-width: 350px;
    padding-left: 30px;
}

.for-sale-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 5px;
}