.ranches-info-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 45%;
    min-width: 300px;
    border-radius: 2px;
    box-shadow: 2px 2px 6px darkgray;
    margin: 0 15px 30px 15px;
    height: inherit;
}

.ranches-info-container-dark{
    box-shadow: 2px 2px 6px #262626;
}

.ranches-info-header-container{
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #DB9200;
    height: 50px;
    padding: 5px;
    border-radius: 2px 2px 0 0;
    margin-bottom: 20px;
}