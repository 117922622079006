.private-note-container{
    width: 30%;
    min-width: 300px;
    height: 300px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    box-shadow: 2px 2px 6px darkgray;
    border-radius: 2px;
    margin-bottom: 30px;
}

.private-note-container-dark{
    box-shadow: 2px 2px 6px #262626;
}

.open-private-note-container{
    position: fixed;
    left: 50%;
    top: 50%;
    width: 45%;
    min-width: 300px;
    min-height: 300px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
    z-index: 1;
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 6px slategray;
    border-radius: 3px;
}

.open-private-note-container-dark{
    box-shadow: 2px 2px 6px #262626;
}

.private-note-content{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 8;
    text-overflow: ellipsis;
    align-self: flex-start;
}

.private-note-btns{
    width: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.edit-note-container{
    width: 100%;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.edit-note-title-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}