.category-card-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: rgba(248, 248, 248, .9);
    box-shadow: 5px 5px 5px darkgray;
    margin: 20px;
}

.category-card-header-container{
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
}

.category-card-subcat-container{
    width: 100%;
    padding: 10px 20px;
}
