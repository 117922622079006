.search-horses-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 50px;
    background-color: #f8f8f8;
}

.search-horses-nav-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.search-horses-filters-container {
    width: 95%;
    max-height: 1200px;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(200, 200, 200, .95);
}

.search-horses-filters-container-dark {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(50, 50, 50, .95);
}

.search-horses-filters-sub-container{
    width: 85%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.search-horses-filter-sub-container{
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 15px 30px 15px;
}

.search-horses-filter-items-container{
    display: flex;
    max-height: 380px;
    flex-flow: column wrap;
    margin: 5px 20px 5px 0;
}

.search-horses-close-clear-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}

.search-horses-horses-container{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}