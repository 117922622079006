.search-container {
    width: 100%;
}

.search-horses-tab{
    width: 100%;
    padding: 0;
    background-color: #f8f8f8;
}

.search-tab{
    width: 100%;
    padding: 0;
    background-color: #f8f8f8;
}