.offspring{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.offspring-link{
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    min-width: 300px;
    margin: 10px;
}

.offspring-link:hover {
    color: inherit;
    text-decoration: inherit;
    display: inline-block;
    width: 28%;
    margin: 10px;

}