.past-shows-container {
    position: relative;
    min-height: 500px;
    background-color: #e3e3e3;
    margin-top: 50px;
    width: 100%;
}

.past-shows-filters-container {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(200, 200, 200, .95);
}

.past-shows-filters-container-dark {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(50, 50, 50, .95);
}

.past-shows-filters-sub-container{
    width: 85%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.past-shows-filter-sub-container{
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 15px 30px 15px;
}

.past-shows-filter-items-container{
    display: flex;
    max-height: 380px;
    flex-flow: column wrap;
    margin: 5px 20px 5px 0;
}

.past-shows-close-clear-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}