.global-chat-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: rgba(248, 248, 248, .9);
    position: fixed;
    right: 20px;
    bottom: 0;
    min-height: 500px;
    min-width: 300px;
    z-index: 30;
}

.global-chat-header-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
}

.global-chat-messages-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    padding: 15px;
}

.global-chat-new-message-container{
    margin-top: 10px;
    padding: 15px;
    align-self: center;
}