.private-sales-leases-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.private-sales-leases-sub-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 20px;
}