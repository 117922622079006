.banner-container{
    position: relative;
    width: 100%;
}

.banner-background-image{
    width: 100%; /* Automatically adjust the width */
    height: 100%; /* Fill the entire height of the parent div */
    position: absolute; /* Position the image */
    top: 0; /* Align to the top of the parent div */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Move back half of the width to center the image */
    object-fit: cover;
}

.banner-profile-image-cropper{
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50px;
    right: 50%;
    transform: translateX(50%);
    overflow: hidden;
    border-radius: 50%;
    z-index: 1;
    background-color: #999999;
}

.banner-profile-image{
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
}

.banner-ranch-info-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    background-color: rgba(256, 256, 256, .7);
    padding: 30px;
    z-index: 1;
}

.banner-ranch-info-item-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: 5px;
}