.ranch{
    
}

.ranch-banner{
    width: 100%;
}

.info{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
}

.ranch-tabs{
    background-color: #044242;
    color: white;
    width: 100%;
    display: flex;
    justify-items: center;
}

.ranch-tab{
    width: 100%;
    padding: 0;
}