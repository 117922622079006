.new-public-note-container{
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    flex-grow: 1;
}

.ql-snow .ql-picker.ql-header{
    color: inherit;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    background-color: inherit;
}

.ql-picker-label.ql-active {
    color: #e6aa07;
}

.ql-picker-label{
    color: inherit;
}

.ql-picker-label:before{
    color: inherit;
}

.ql-list {
    color: inherit;
}

.ql-clean {
    color: inherit;
}

.ql-link {
    color: inherit;
}

.ql-underline {
    color: inherit;
}

.ql-italic {
    color: inherit;
}

.ql-bold {
    color: inherit;
}

.ql-clean {
    color: inherit;
}

.ql-snow .ql-stroke{
    color: inherit;
    stroke: currentColor !important;
}

.ql-fill{
    color: inherit;
    stroke: none;
    fill: currentColor !important;
}