.mail-message-container{
    padding: 5px 20px;
}

.mail-message-header-container{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 10px;
}

.mail-message-from-container{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 5px;
}

.mail-message-to-container{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;
    width: 98%;
}