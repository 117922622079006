.results-container{
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    justify-content: center;
}

.show-results-title{
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 1.4em;
    margin-top: 20px;
}