.form{
    margin: 20px;
}

.field{
    margin: 10px;
}

.button{
    margin: 10px;
}

/*
table{
    margin: 10px;
}

table, th, td{
    border: 1px solid black;
}

td{
    width: 100px;
    text-align: center;
}*/
