.public-note-container{
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 30px;
    margin: 30px 15px 15px 15px;
    background-color: #f8f8f8;
    border-radius: 5px;
}

.public-note-container-dark{
    background-color: #3A3A3A;
}

.public-note-btns{
    position: absolute;
    right: 20px;
    top: 20px;
}

.edit-public-note-container{
    width: 100%;
    flex-grow: 1;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    align-self: center;
}