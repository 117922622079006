.conformation{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: auto;
    padding: 30px;
}


@media screen and (max-width: 600px) {
    .conformation {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: auto;
        padding: 10px;
    }
}