.account-management-container{
    padding: 20px;
    margin: 20px;
}

.profile-image-upload-container{
    margin-bottom: 20px;
}

.profile-image-upload-text{
    font-family: "Roboto Black", sans-serif;
    font-size: 20px;
    font-weight: bold;
}