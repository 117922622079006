.gaits-container{
    width: 96%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-self: flex-end;
}

.gaits-img{
    width: 100%;
    height: auto;
    margin: 20px;
}

@media screen and (max-width: 600px) {
    .gaits-container{
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: center;
    }

    .gaits-img{
        width: 70%;
        height: auto;
        margin: 20px;
    }
}