.comparison-card-container {
    width: 45%;
    min-width: 300px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 20px;
}

.comparison-card-horse-container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.comparison-card-horse-header-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.comparison-gaits-img{
    height: 80px;
}

.comparison-charts {
    min-width: 300px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-content: center;
}

.comparison-tests-table-container{
    width: 100%;
    padding: 20px;
    margin-top: 20px;
}

.comparison-conformation-container{
    width: 100%;
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}