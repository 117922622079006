.finances-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.finances-table-container{
    position: relative;
    width: 95%;
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    border-radius: 3px;
    box-shadow: 2px 2px 4px darkgray;
    padding-bottom: 10px;
}

.finances-title-container{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.finances-filters-container {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(200, 200, 200, .95);
}

.finances-filters-container-dark {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    padding: 20px;
    background-color: rgba(50, 50, 50, .95);
}

.finances-filters-sub-container{
    width: 85%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.finances-filter-sub-container{
    display: flex;
    flex-flow: column nowrap;
    margin: 5px 15px 30px 15px;
}

.finances-filter-items-container{
    display: flex;
    max-height: 380px;
    flex-flow: column wrap;
    margin: 5px 20px 5px 0;
}

.finances-close-clear-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}