.clubs-banner-img{
    width: 100%;
}

.active-clubs-container{
    min-height: 150px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.discipline-clubs-container{
    padding: 50px
}

.breed-clubs-container{
    padding: 50px
}