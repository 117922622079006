.sales-card-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 28%;
    background-color: white;
    border-radius: 5px;
    border: 15px solid white;
    margin: 10px;
    min-width: 330px;
    position: relative;
    color: black;
}

.sales-info-container{
    margin-top: 15px;
}

.sales-name{
    font-family: "Roboto Slab ExtraBold", serif;
    font-size: 1.2em;
    text-align: left;
    margin-bottom: 4px;
}

.sales-breed{
    font-family: "Roboto Condensed Bold", sans-serif;
    font-size: .8em;
    text-align: left;
    margin-bottom: 2px;
}

.sales-parents{
    font-family: "Roboto Condensed Light", sans-serif;
    font-size: .6em;
    margin-bottom: -3px;
}

.sales-purchase-info{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-top: 15px;
}

.sales-price{
    font-family: "Roboto", sans-serif;
    font-weight: bolder;
    text-align: right;
}

.sale-gender-img{
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    height: 30px;
}

.sales-prefix{
    font-family: "Roboto Thin", sans-serif;
    font-weight: bold;
    font-size: .8em;
    vertical-align: top;
}

.sales-name-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
}
