.rankings-tabs{
    background-color: #044242;
    color: white;
    width: 100%;
}

.rankings-tab{
    width: 100%;
    padding: 0;
}

.horse-points-container{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
}

.horse-points-filters{
    display: flex;
    flex-flow: row nowrap;
    width: 80%;
    justify-content: space-between;
    margin-bottom: 50px;
}

.breed-rankings-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}