.health-container{
    width: 100%;
    passing: 0;
}

.conditions-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 20px;
}