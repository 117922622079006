.forum-tab{
    padding: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.forum-search-results-container{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}